.collectionCardEach, .nftCardEach {
  /* border: 2px solid red; */
  /* width: 17.185vw !important; */
  /* height: 16vw; */
  overflow: hidden;
  background-color: #f8f8f8;
  border-radius: 13px;
  margin-right: 5.3%;
}
.nftCardEach:nth-child(4n + 1){
  margin-right: 0px;
}
.collectionCardEach:nth-child(4n + 1){
  margin-right: 0px;
}
.collectionCardEach a {
  text-decoration: none !important;
}

.collectionCardEachName {
  margin-top: 1.65rem !important;
  margin-bottom: 8px;
}
.collectionCardEachTotalitems{
  margin-bottom: 0;
}
/* .collectionCardEachTotalitems {
  margin-top: 1% !important;
} */
.collectCard{
  height: 309px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* nft and collection mediaquery start */
@media only screen and (min-width: 1790px){
  .collectionCardEach, .nftCardEach{
    width: 19.5931%;
    margin-right: 7.2%;
  }
 .collectCard{
    height: 309px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
/* @media only screen and (max-width: 1600px) {
  .collectionCardEach, .nftCardEach {
    margin-right: 70px;
  }
}
@media only screen and (max-width: 1440px) {
  .collectionCardEach, .nftCardEach {
    margin-right: 65px;
  }
} */
@media only screen and (max-width: 1366px) {
  .collectionCardEach, .nftCardEach {
    margin-right: 5%;
  }
}
@media only screen and (max-width: 1024px) {
  .collectionCardEach {
    /* border: 2px solid red; */
    /* width: 37.185vw !important;
    height: 41vw; */
  }
  .collectionCardEach, .nftCardEach {
    margin-right: 5.3%;
  }
}
@media only screen and (width: 991px){
  .collectionCardEach, .nftCardEach {
    margin-right: 0px;
  }
}
@media only screen and (min-width:720px) and (max-width: 990px) {
  .collectionCardEach, .nftCardEach {
    margin-right: 6%;
  }
  .nftCardEach:nth-child(odd){
    margin-right: 0px;
  }
  .collectionCardEach:nth-child(odd){
    margin-right: 0px;
  }
}
@media only screen and (max-width: 719px){
  .collectionCardEach
  /* .nftCardEach*/ { 
    margin-right: 0px;
  }
}
@media only screen and (max-width: 480px) {
  /* .collectionCardEach {
    border: 2px solid red;
    width: 87.185vw !important;
    height: 86vw;
  } */
}
/* nft and collection mediaquery end */