
#like_icon {
    position: absolute;
    /* margin-top: 7px; */
    top: 12px;
    /* margin-right: 10px; */
    right: 6px;
    width: 40px;
    height: 40px;
    transition: all .4s;
  }
  #unlike_icon {
    position: absolute;
    /* margin-top: 7px; */
    top: 12px;
    /* margin-right: 10px; */
    right: 6px;
    width: 26px;
    height: 26px;
    transform: scale(1.0);
    transition: all .4s;
  }
  #unlike_icon:hover{
    transform: scale(1.3);
    transition: all .4s;
  }
  .nft_card:hover {
    transform:scale(1.02);
    box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
    transition: all 0.1s ease 0s;
  }
  .currency-sign{
    margin-right: 2px;
    margin-left: 2px;
  }
  .currency-sign-nftinformation{
    margin-right: 2px;
    margin-left: 5px;
  }
  .musicIcon{
    position: absolute;
    top: 45%;
    left: 83%;
  }
  .homePageContainer .musicIcon{
    position: absolute;
    top: 61%;
    left: 88%;
  }
  /* .videoDiv {
    position: relative;
    padding-top: 56.25% 
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  } */