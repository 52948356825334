.editProfileButton {
  font-size: 0.9vw !important ;
  font-family: "poppins" !important;
  color: #366eef;
  border: 2px solid #336eef;
  border-radius: 5px;
  right: 8rem;
  bottom: 2rem;
  margin-top: 2rem;
  /* margin-right: 2rem; */
  padding: 4px 20px;
  font-size: 12px;
}
.profileItemContainer {
  width: 86% !important;
  margin: auto;
}
.profileNftContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-top: 59.19px;
}
.profileNftContainerInner {
  /* width: 17.085vw !important; */
  width: 21%;
  padding: 0%;
  margin: 0%;
  /* margin-right: 1.2vw; */
  /* border: 2px solid blue; */
}
.profileNftContainerInner img {
  /* width: 17.085vw !important; */
  height: 9.81vw !important;
  border-radius: 4px;
  width: 100%;
  object-fit: cover;
}
.profileNftDetailFirstContainer {
  /* border: 2px solid green; */
  padding: 3% 3% !important;
}
.profileNftDetailSecondContainer {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  font-size: 0.9vw;
  font-family: "poppins";
  padding: 4% 3% !important;
}
.postTypeProfileContainer {
  border-bottom: 1px solid #c9c9c9;
  height: 32px;
  margin-top: 0px;
  width: 40%;
}
.postTypeProfile {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  cursor: pointer;
  /* font-family: 'poppins';
  font-size: 14px;
  line-height: 21px; */
  font: normal normal normal 14px/21px Poppins;
  color: #707070;
  padding: 0px 4px 11px 4px;
  justify-content: center;
}
.postTypeProfile--active {
  border-bottom: 2px solid #000000;
  height: 100% !important;
  font-family: "poppins-semibold";
  color: #000000;
}
.wallet-address-text {
  /* width: 101px; */
  background: #F3F3F3 0% 0% no-repeat padding-box;
  border-radius: 6px;
  /* width: 10%; */
  /* display: flex; */
  /* margin-top: 5px; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
   padding: 4px 4px 3px 4px; 
}
.addressText{
  margin-bottom: 0;
  font: normal normal normal 16px/25px Poppins;
  color: #191919;

}
.profile-user {
  font-family: 'poppins-bold';
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 22px;
}

.add-cover {
  display: flex;
  margin-bottom: 21px;
  text-align: center;
  align-items: center;
  width:auto;
  /* border: 1px solid red; */
  justify-content: center;
  margin-left: 44px;
}
.add-cover .copy-img{
  width: 21.47px;
  height: 21.47px;
  margin-left: 20px;
  cursor: pointer;
}
.add-cover .copy-img:hover{
  box-shadow: 0 2px 2px #e2e2e2;
}
/* .hover-cls:hover .pencilicon{
  display: block;
} */
.profilecover {
  height: 280px;
  width: 100%;
  object-fit: cover;
  /* object-fit: fill;  */
}
/* .profilecover:hover{
  opacity:0.8;
} */
.pencilicon {
  /* display:none; */
  position: absolute;
  right: 1%;
  height: 16px;
  width: 16px;
  bottom: 8%;
  cursor: pointer;
}
.profileavatar {
  border-radius: 50%;
}
.profileeditbutton {
  width: 145px;
  height: 42px;
  border: 1px solid #366eef;
  border-radius: 4px;
  text-align: center;
  background-color: white;
  color: #366eef;
  position: absolute;
  z-index: 0;
  right: 47px;
  bottom: -90px;
}
.profileeditbuttonatbottom {
  display: none;
  width: 145px;
  height: 42px;
  border: 1px solid #366eef;
  border-radius: 4px;
  text-align: center;
  background-color: white;
  color: #366eef;
  text-decoration: none;
}
.textdecornone {
  text-decoration: none;
  margin-bottom: 37px;
}

/* my profile page css */
.profileavatar.absolute{
  margin-top: -105px;
}
.profileavatar.absolute .user-img{
  width: 198px;
  height: 198px;
  margin-bottom: 14px;
  border: 4px solid #fff;
  object-fit: cover;
  border-radius: 50%;
}
.profile-description{
  /* font-family: 'poppins';
  font-size: 16px;
  line-height: 25px;
  color: #000000; */
  text-align: center;
font: normal normal normal 16px/25px Poppins;
letter-spacing: 0px;
color: #000000;
  margin-bottom: 18px;
  width: 35%; 
}
.profile-portfolio{
  font-family: 'poppins';
  font-size: 16px;
  line-height: 25px;
  color: #707070;
  margin-bottom: 47px;
}
.profileItemContainer .nftTileContainer .nftCardEach:nth-child(4n + 1){
  margin-right: 5.3%;
}
.profileItemContainer .nftTileContainer .nftCardEach:nth-child(4n+4) {
  margin-right: 0px;
}
.copyButton{
  width:21px;
  height:21px;
  cursor:pointer;
  margin-left: 26.6px;
}
.editTextAnchor{
  display: none;
}
.globalImg{
  margin-right: 6px;
  width: 15px;
  height: 15px;
}


@media only screen and (max-width: 1024px) {
  .editProfileButton {
    font-size: 1.9vw !important ;
    font-family: "poppins" !important;
  }
  .profileItemContainer {
    width: 90% !important;
    /* margin-top: 59.12px; */
  }
  .profileNftContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-top: 59.19px;
  }
  .profileNftContainerInner {
    width: 42.085vw !important;
    margin-right: 2.7vw;
    /* border: 2px solid blue; */
  }
  .profileNftContainerInner img {
    width: 100% !important;
    height: 27.085vw !important;
    object-fit: cover;
  }
  .profileNftDetailFirstContainer {
    /* border: 2px solid green; */
    padding: 3% 3% !important;
  }
  .profileNftDetailSecondContainer {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
    font-size: 1.9vw;
    font-family: "poppins";
    padding: 4% 3% !important;
  }
  .postTypeProfile {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    cursor: pointer;
  }
  .postTypeProfile--active {
    border-bottom: 2px solid #000000;
    height: 100% !important;
    font-family: "poppins-semibold";
  }
  .postTypeProfileContainer{
    width: 50%;
  }
}
@media only screen and (min-width: 720px) and (max-width: 990px){
  .profileItemContainer .nftTileContainer .nftCardEach{
    margin-right: 10%!important;
  }
  .profileItemContainer .nftTileContainer .nftCardEach:nth-child(even){
    margin-right: 0px!important;
  }
}

@media only screen and (max-width: 769px) {
  .postTypeProfileContainer{
    width: 100%;
    justify-content: center;
  }
  .copyButton{
    margin-left: 26.6px;
  }
  .profile-user{
    font: normal normal 600 20px/30px Poppins;
  }
  .user-img{
    width: 120px!important;
    height: 120px !important;
    object-fit: cover;
  }
  .profileavatar.absolute{
    margin-top: -63px;
  }
  .profile-portfolio{
    margin-bottom: 32px;

  }
  .profileeditbutton {
    display: block;
    margin-top: 20px;
    text-decoration: none;
    right: 2%;
  }
  .bottombutton{
    display: none;
  }
 
  
}

@media screen and (max-width: 767px) {
  
}

@media only screen and (max-width: 520px) {
  .editProfileButton {
    font-size: 3.4vw !important ;
    font-family: "poppins" !important;
  }
  .profileItemContainer {
    /* width: 100% !important; */
    margin: 0px auto;
  }
  .profileNftContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: border-box;
  }
  .profileNftContainerInner {
    width: 82.085vw !important;
    /* margin-right: 2.7vw; */
    margin: 1% auto;
    /* border: 2px solid blue; */
  }
  .profileNftContainerInner img {
    /* width: 82.085vw !important; */
    height: 47.085vw !important;
    width: 100%;
    object-fit: cover;
  }
  .profileNftDetailFirstContainer {
    /* border: 2px solid green; */
    padding: 3% 3% !important;
  }
  .profileNftDetailSecondContainer {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
    font-size: 3.9vw;
    font-family: "poppins";
    padding: 4% 3% !important;
  }
  .postTypeProfile {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    cursor: pointer;
    margin-right: auto!important;
  }
  .postTypeProfile--active {
    border-bottom: 2px solid #000000;
    height: 100% !important;
    /* font-family: "poppins-semibold"; */
    font: normal normal 600 14px/21px Poppins;
  }
  .profile-user{
    font: normal normal 600 18px/27px Poppins;
    letter-spacing: 0px;
    color: #191919;
    margin-bottom: 16px;
  }
  .copyButton{
    width:21px;
    height:21px;
    margin-left: 11.6px;
    cursor:pointer;
  }
  .addressText{
    margin-bottom: 0;
    font: normal normal normal 14px/21px Poppins;
  }
  .wallet-address-text{
    padding: 6px 10px 4px 4px; 
  }
  .profile-description{
    font: normal normal bold 14px/21px Poppins;
    letter-spacing: 0px;
    color: #191919;
    opacity: 1;
    width: 85%;
  }
  .profile-portfolio{
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #707070;

  }
  .globalImg{
    width: 15px;
    height: 15px;
    margin-right: 12px;
  }
  .edit-text{
    position: absolute;
    color: #366EEF;
    margin-top: 16px;
    margin-right: 18px;
    font-size: 12px;
    left: 92%;
    line-height: 18px;
    font-family: Poppins;
    font-weight: 600;
  }
  .editTextAnchor{
    display: block;
  }
  .textdecornone{
    display: none;
  }
  .user-img{
    width: 100px !important;
    height: 100px !important;
    /* margin-top: 54px; */
    border: 3px solid #FFFFFF !important;
    border-radius: 171px !important;
    object-fit: cover;
  }
  .profile-portfolio{
    margin-bottom: 26px;
  }
  .profilecover{
    height: 195px;
    object-fit: cover;
  }

}
@media screen and (max-width: 992px){
  .profile-description{
    width: 65%;
  }
}

.profileeditbutton:hover{
  font-size: 17px;
  transition: all .4s;
}

.profileImg-Container-myprofile{
  overflow: hidden;
  position: relative;
  display: inline-block;
}
.profileImageLoader{
  height: 198px;
  width: 198px;
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
  border: 4px solid #fff;
  border-radius: 50%;
}
.thumbnailCirular{
  width: 189px !important;
  height: 177px !important;
  margin-bottom: 0px !important;
}

.add-cover{
  position: relative;
}

.tooltiptext-myprofile{
  visibility: visible;
  width: 182px;
  background-color: rgba(0, 0, 0, 0.681);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 999999;
  top: 123%;
  left: 27%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 1s;
}
.add-cover .tooltiptext-myprofile::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent  rgba(0, 0, 0, 0.681) transparent;
}

.add-cover:hover .tooltiptext-myprofile {
  visibility: visible;
  opacity: 1;
}
.Container-clipboard{
  display: flex;
  align-items: center;
}