
body.active-modal{
  overflow-y: hidden;
}
.report-outer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  z-index: 100;
}
.report-abs-modal {
  position: relative;
  width: 100%;
  height: 100%;
  /* backdrop-filter: blur(0px) contrast(0.1); */
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.report-modal {
  position: absolute;
  background-color: white;
  opacity: 1 !important;
  top: 126px;
  border-radius: 13px;
}
.make-offer-modal {
  position: absolute;
  background-color: white;
  opacity: 1 !important;
  top: 126px;
  border-radius: 13px;
}
.report-inner {
  width: 85.8%;
  opacity: 1;
  margin: auto;
}
.reportthisitem {
  display: flex;
  justify-content: space-between;
  padding-top:34px;
}
.cross-icon {
  color: #686868;
}
.report-text {
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #191919;
  margin-bottom:0px;
}
.reason-text{
  margin-bottom: 13px;
  font-weight: bold;
  font-size: 16px;
  color: #191919;
}
.singlerowmodal {
  padding-top: 28px;
}
.select-box{
  width:100%;
  height: 42px;
  padding: 10px 24px 9px 17px;
  font-size: 16px;
  color:#828282;
  font-weight: 500;
  border: 1px solid #C8C8C8;
  background: #FFFFFF;  
  border-radius: 6px;  
  margin-top: 13px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat; 
  background-position:97% center;
}
.report-btn {
  width: 100%;
  background-color: #9AB6F7;
  margin-top: 34px;
  margin-bottom: 41px;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  height: 42px;
}
.removeSaleButton{
  float: right;
  padding-top: 31px;
  padding-Bottom: 31px;
}
.RemoveButton{
background: #366EEF 0% 0% no-repeat padding-box;
border-radius: 6px;
opacity: 1;
padding: 10px;
color: #FFFFFF;
font-size: 16px;
line-height: 25px;
height: 42px;
border: none;
}
.CancelButton{
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #366EEF;
border-radius: 6px;
opacity: 1;
color: #366EEF;
height: 42px;
padding: 8px;
margin-right: 14px;
}
.HeadingText{
  font-size: 16px;
  line-height:25px;
  font-weight: normal;
  font-style: normal;
  color: #191919;
  opacity: 1;
}
.MainHeadingText{
  font-size: 18px;
  line-height: 27px;
  font-weight: bold;
  color: #191919;
  margin: auto 0;
}
.closeIcon{
  cursor: pointer;
}
.offerHeading{
  display: flex;
  justify-content: space-between;
  padding-top:34px;
}
.price-heading-text{
  text-align: left;
font: normal normal bold 16px/25px Poppins;
letter-spacing: 0px;
color: #191919;
opacity: 1;
}
.input-group-price{
  border: 1px solid #C8C8C8;
  border-radius: 6px;
  height: 42px;
  display: flex;
  justify-content: left;
  
}
.symbolText{
padding: 1.9% 3.61% 3.62% 1.8%;
display: flex;
cursor: default;
}
.eth-value{
letter-spacing: 0px;
line-height: 25px;
font-weight: normal;
font-style: normal;
font-family: Poppins;
font-size: 16px;
width: auto;
height: auto;
color: #191919;
}
.price-input-box{
  border: none;
  display: inline-block;
  width: 81%;
  padding-left:17.39px;
}
.input-group-price input::-webkit-input-placeholder {
  /* WebKit browsers */
  text-align: left;
  font: normal normal medium 16px/25px Poppins;
  letter-spacing: 0px;
  color: #c3c3c3;
  opacity: 1;
  
}
.heading-second-row{
  text-align: left;
  margin-top: 20px;
 font: normal normal bold 16px/25px Poppins;
 letter-spacing: 0px;
 color: #191919;
 opacity: 1;
}
.expiry-div{
  height: 42px;
  margin-top: 13px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #C8C8C8;
  border-radius: 6px;
  display: flex;
}
.filter-time{
  border: none;
  font-size: 16px;
  line-height: 25px;
  font-weight: normal;
  font-size: medium;
  font-style: normal;
  margin-left: 17.09px;
  display: flex;
  flex-direction: row-reverse;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  color:#828282;
  cursor: pointer;
}

.offer-button{
  width: 100%;
  background-color: #366EEF;
  border-radius: 6px;
  color: #ffffff;
  height: 42px;
  margin-top: 28px;
  margin-bottom: 48px;
  border: none;
}
/* mobile-view */
@media screen and (max-width:426px){
  .report-modal{
    width:85%;
    left:7.5%;
  }
  .make-offer-modal{
    width:85%;
    left:7.5%;
  }
}

/* tab-view */
@media screen and (min-width:426px) and (max-width:769px){
  .report-modal{
    width:82%;
    left: 69px;
  }
  .make-offer-modal{
    width:82%;
    left: 52px;
  }
 
}

/* windows-view */
@media screen and (min-width:769px){
  .report-modal{
    width: 32.8%;
    /* left: 35.6%; */
    /* width: 35%; */
    /* left: 25%; */
  }
  .report-abs-modal .NewHomeCard{
    width: 581px;
  }
  .main-model{
    width: 36%;
    left: 37%;
    }

  .main-model-makeoffer{
     width: 32.82%;
   left: 37%;
   }
}
.nftdetail-img .imginfo_mob:hover{
  box-shadow: rgba(8, 124, 232, 0.83) 0px 0px 20px 0px;
  transition: all .7s;
  cursor: pointer;
}
.saleDateTime{
  width: 100%;
}
.report-abs-modal .NewHomeCard{
background: #031527 0% 0% no-repeat padding-box;
border-radius: 12px;
opacity: 1;
}
.report-inner .NewHomeButton{
  width: 100%;
background: #FFFFFF 0% 0% no-repeat padding-box;
border-radius: 6px;
letter-spacing: var(--unnamed-character-spacing-0);
font: normal normal medium 16px/25px Poppins;
letter-spacing: 0px;
color: #031527;
}
.newhometext{
font: normal normal 600 18px/27px Poppins;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
}
.input-price .newhomelabel{
font: normal normal normal 16px/25px Poppins;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
margin-top: 32px;
}
.Address{
  width: 420px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
background: #253C54 0% 0% no-repeat padding-box;
border: 1px solid #00AF51;
border-radius: 6px;

}
.WalletAddress{
  text-align: left;
font: normal normal normal 14px/21px Poppins;
letter-spacing: 0px;
color: #E8E8E8;
}
.sitediv{
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}
.siteurl{
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #E8E8E8;
  opacity: 1;
  margin-left: 5px!important;
}
.lastLabel{
text-align: left;
font: normal normal normal 12px/18px Poppins;
letter-spacing: 0px;
color: #E8E8E8;
margin-top: 16px;
}

.new-abs-modal{
  backdrop-filter: blur(0px) contrast(0.8);
}
.cross-icon{
  cursor: pointer;
}