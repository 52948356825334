.spinnerloader {
  width: 100%;
  display: flex;
  justify-content: center;
}
.input-outer {
  position: relative;
}
.fileInput {
  opacity: 0;
  position: absolute;
  /* width: 100%; */
  width: 100px;
  /* border: 1px solid red !important; */
  height: 100%;
  /* z-index: 10; */
}
.upload-file-outer {
  position: relative;
  width: 300px;
  border: 1px solid rgb(207, 207, 207);
  height: 300px;
  margin-bottom: 20px;
  display: flex;

  align-items: center;
  justify-content: center;
}
.upload-image-upper {
  /* width: 40%; */
  /* margin: auto; */
  /* position: absolute; */
  /* position: absolute; */
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* left: 50%; */
  /* top: 50%; */
  /* top: auto; */
  /* left: auto; */
}
.upload-image-upper img {
  height: 30% !important;
}
.upload-image-banner {
  /* position: relative; */
  width: 100%;
  border: 1px solid rgb(207, 207, 207);
  height: 300px;
  margin-bottom: 20px;
}
.bannerwidth {
  width: 100%;
}
.image-upload {
  /* width: 70%; */
  height: 30%;
  object-fit: contain;
  /* z-index: -1; */
  margin: auto;
  /* position: absolute; */
}
.drag-and-drop {
  text-align: center;
  /* font-size: medium; */
  font-family: "poppins-medium";
  margin: 20px 0px;
}
.drag-and-drop-browse {
  color: #366eef;
  font-family: "poppins-semibold";
}

.input-box-1 {
  border: 1px solid rgb(207, 207, 207);
  width: 100%;
  margin-bottom: 20px;
  background-color: transparent;
  padding: 1% 2%;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: 'poppins-medium';
  font-size: 16px;
  line-height: 25px;
  color: #191919;
  resize: none;
}
.block-chain-container {
  /* border: 1px solid rgb(139, 7, 7) !important; */
  border: 1px solid rgb(207, 207, 207);
  padding: 3px 0px;
  display: flex;
  background: #F3F3F3;
  border-radius: 7px;
}
.block-chain-left {
  /* width: 20%; */
}
.block-chain-right {
  width: 100%;
}
.rm-border {
  border: 0px;
  padding: 0px;
  margin: 0px;
  margin-top: 5px;
}

.submit-button {
  width: 100%;
  height: 42px;
  color: white;
  border-radius: 4px;
  text-align: center;
  margin-top: 30px;
}

.category-label {
  margin-top: 20px;
}
.blockchainSelect{
  appearance: none;
}

/* .col-cards-gap {
  gap: 6.57%;
} */
@media screen and (min-width: 1399px) {
  .cards-col-gap {
    gap: calc(16% / 2.5);
  }
}
/* @media screen and (min-width: 1399px) {
  .cards-col-gap {
    gap: calc(16% / 2.4);
  }
} */
@media screen and (max-width: 1399px) {
  .cards-col-gap {
    gap: calc(16% / 2.4);
  }
}
@media screen and (max-width: 1398px) {
  .cards-col-gap {
    gap: calc(16% / 2.1);
  }
}
/* @media screen and (max-width: 1400px) {
  .cards-col-gap {
    gap: calc(16% / 2.4);
  }
} */
@media screen and (max-width: 1248px) {
  .cards-col-gap {
    gap: calc(16% / 2.232);
  }
}
@media screen and (max-width: 1094px) {
  .cards-col-gap {
    gap: calc(16% / 2.31);
  }
}
@media screen and (max-width: 1028px) {
  .cards-col-gap {
    gap: calc(16% / 2.32);
  }
}

@media screen and (max-width: 1519px) {
  .col-cards-gap {
    gap: 6.57%;
  }
}
@media screen and (max-width: 1399px) {
  .col-cards-gap {
    gap: 6.7%;
  }
}
@media screen and (max-width: 1399px) {
  .col-cards-gap {
    gap: 7.86%;
  }
}
@media screen and (max-width: 1352px) {
  .col-cards-gap {
    gap: 7.79%;
  }
}
@media screen and (max-width: 1343px) {
  .col-cards-gap {
    gap: 7.79%;
  }
}
@media screen and (max-width: 1320px) {
  .col-cards-gap {
    gap: 7.78%;
  }
}
@media screen and (max-width: 1321px) {
  .col-cards-gap {
    gap: 7.78%;
  }
}

@media screen and (max-width: 1319px) {
  .col-cards-gap {
    gap: 7.74%;
  }
}
@media screen and (max-width: 1297px) {
  .col-cards-gap {
    gap: 7.64%;
  }
}
@media screen and (max-width: 1256px) {
  .col-cards-gap {
    gap: 7.6%;
  }
}
@media screen and (max-width: 1240px) {
  .col-cards-gap {
    gap: 7.5%;
  }
}
@media screen and (max-width: 1203px) {
  .col-cards-gap {
    gap: 7.4%;
  }
}

@media screen and (max-width: 1167px) {
  .col-cards-gap {
    gap: 7.3%;
  }
}
@media screen and (max-width: 1134px) {
  .col-cards-gap {
    gap: 7.2%;
  }
}
@media screen and (max-width: 1097px) {
  .col-cards-gap {
    gap: 7.1%;
  }
}
@media screen and (max-width: 1062px) {
  .col-cards-gap {
    gap: 7%;
  }
}
@media screen and (max-width: 1027px) {
  .col-cards-gap {
    gap: 6.9%;
  }
}
@media screen and (max-width: 1118px) {
  .col-cards-gap {
    gap: 7.3%;
  }
}
/* @media screen and (max-width: 1115px) {
  .col-cards-gap {
    gap: 7.2%;
  }
} */
@media screen and (max-width: 1113px) {
  .col-cards-gap {
    gap: 6.8%;
  }
}
@media screen and (max-width: 1114px) {
  .col-cards-gap {
    gap: 6.5%;
  }
}
/* @media screen and (max-width: 1024px) {
  .col-cards-gap {
    gap: 0% !important;
  }
} */
@media screen and (max-width: 820px) {
  .col-cards-gap {
    gap: 0% !important;
  }
}

@media screen and (max-width: 764px) {
  .upload-file-outer {
    width: 100%;
    /* margin: auto; */
  }
}

.fw-b{
  font-weight: bold;
}
.fw-600{
  font-weight: 600;
}
.fs-14{
  font-size: 14px;
}
.fs-16{
  font-size: 16px;
}
.fs-32{
  font-size: 32px;
}
.c-b{
  color:#191919;
}
.c-bl{
  color:"#366EEF";
}
.c-g{
  color:"#707070";
}
.pt-50{
  padding-top:50px;
}
.pt-20{ 
  padding-top: 20px;
}
.pb-20{
  padding-bottom: 20px;
}
.main-container {
  width: 50%;
  margin: auto;
  margin-top:90px;
}
.collectionContainer{
  width: 37%;
}
.img-div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 244px;
  height: 244px;
  border: 1px solid #C8C8C8;
  border-radius: 7px;
}
.img-sec-div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 244px;
  border: 1px solid #C8C8C8;
  border-radius: 7px;
}
.upload-icon{
  width:100px;
  height: 100px;
}

/* mobile-view */
@media screen and (max-width:426px){
  .main-container {
    width: 91.42%;
    margin: auto;
    margin-top:60px;
  }
  .fs-32{
    font-size: 18px;
  }
  /* .title{
    text-align: center;
  } */
  .img-div{
    width: 240px;
    height: 240px;
  }
  .img-sec-div{
    height: 200px;
  }
  .collectionImage{
    width: 241px;
    height: 240px;
  }
  .collectionBannerImage{
    width: 343px;
    height: 201px;
  }
  .heading-common-collection{
    padding-top: 0px!important;
    margin-bottom: 13px!important;
    padding-bottom: 0px!important;
  }
  .input-common-collection{
    margin-bottom: 0px !important;
  }
  .collectionCommonDiv{
    margin-top: 22px;
  }
  .collectionCommonDivFirst{
    margin-top: 32px;
  }
  .uploadCollectionBanner{
    padding-top: 43px;
    padding-bottom: 13px;
  }
  .uploadCollectionImage{
    padding-top: 10px;
    margin-bottom: 18px;
  }
  
}
@media screen and (max-width:767px){

  .title{
    text-align: initial !important;
    width: 100% !important;
    font-size: 16px !important;
    margin-top: 3px;
  }
  .collectiontitle{
    text-align: center!important;
    font: normal normal 600 18px/27px Poppins !important;
  }

}

/* tab-view */
@media screen and (min-width:426px) and (max-width:1025px){
  .main-container {
    width: 90%;
    margin: auto;
    margin-top:40px;
  }
  .title{
    text-align: center;
    font-size: 20px;
  }
  .pt-50{
    padding-top: 44px;
  }
  .img-div{
    width: 340px;
    height: 340px;
  }
}
@media only screen and (min-width:768px) and (max-width:1025px) {
  .collectionImage{
    width: 341px;
    height: 341px;
  }
  .collectionBannerImage{
    width: 684px;
    height: 244px;
  }  
  

}
.collectionImage{
  border: 1px dashed #C8C8C8;
}
.collectionBannerImage{
  border: 1px dashed #C8C8C8;
}
.categoryCollection{
  appearance: none;
  background-image: url("../images/drop-down-arrow.svg");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 11px;
  padding-left: 12px;
  height: 42px;
}
.block-chain-right .css-1s2u09g-control{
  min-height: 42px;
}