.inputbox {
  background-color: #f8f8f8;
  width: 459px;
  height: 42px;
  padding: 0px;
  padding-left: 10px;
  border-radius: 4px 0px 0px 4px;
  opacity: 1;
  border: none;
  font-weight: bold;
}
.inputbox:focus,
.inputbox:hover {
  background-color: #f8f8f8;
}
@media only screen and (max-width: 1600px) {
  .inputbox {
    width: 75%;
  }
}

@media only screen and (min-width: 700px) and (max-width: 1000px) {
  .inputbox {
    width: 291px;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1300px) {
  .inputbox {
    width: 100px;
  }
}
.notification-icon {
  width: 28px;
  height: 30px;
  cursor: pointer;
}
.profile-icon {
  width: 31px;
  height: 31px;
}
.wallet-icon {
  width: 28px;
  height: 30px;
}
.create-btn {
  width: 146px;
  height: 42px;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  background-color: #366eef;
}

/* mobile-view */
@media screen and (max-width: 426px) {
  .notification-icon {
    width: 26px;
    height: 26px;
  }
  .profile-icon {
    width: 26px;
    height: 26px;
  }
  .wallet-icon {
    width: 26px;
    height: 26px;
  }
}
#hover-image {
  background: lightgrey;
}
#hover-no {
  background: none;
}
.overflow {
  overflow: hidden !important;
  position: fixed !important;
  width: 100%;
}
.overflow-hidden {
  overflow: auto;
}
.hover-icon {
  width: 28px;
  height: 30px;
  background: lightgrey;
  cursor: pointer;
  /* border-radius: 50%; */
}
.end-noti {
  text-align-last: center;
  font-family: "poppins";
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #191919;
  opacity: 1;
  width: 187px;
  height: 24px;
  background: #edf2fd 0% 0% no-repeat padding-box;
  border-radius: 6px;
}

.create-btn:hover {
  background-color: rgb(10, 74, 212);
  color: rgb(253, 253, 253);
  border:1px solid grey;
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
  transition: all 0.1s ease 0s;
}

.store-logo{
  width: 100%;
  /* height:42px; */
  /* border-radius: 10px; */
}
.store-name{
  color:black;
  font-weight: bold;
  font-size: 20px;
  padding-left: 10px;
}
input[type="radio" i] {
  background-color: initial;
  cursor: pointer;
  appearance: auto;
  box-sizing: border-box;
  margin: 3px 3px 0px 5px;
  padding: initial;
  border: initial;
}
